const edacFrontendFixes = window.edac_frontend_fixes || {};

if ( edacFrontendFixes?.missing_or_empty_page_title?.enabled ) {
	// lazy import the module
	import( /* webpackChunkName: "missing-or-empty-page-title" */ './Fixes/missingOrEmptyPageTitleFix' ).then( ( missingOrEmptyPageTitleFix ) => {
		missingOrEmptyPageTitleFix.default();
	} );
}

if ( edacFrontendFixes?.add_label_to_unlabelled_form_fields?.enabled ) {
	// lazy import the module
	import( /* webpackChunkName: "add-label-to-unllabeled-form-fields" */ './Fixes/addLabelToUnlabelledFormFieldsFix' ).then( ( addLabelToUnlabelledFormFieldsFix ) => {
		addLabelToUnlabelledFormFieldsFix.default();
	} );
}
